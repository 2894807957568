<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5>Approve Void</h5>
            </template>
            <b-row>
                <b-col>
                    <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                        <div class="d-flex search-input-wrapper">
                            <b-form-input class="ml-2 mr-2" v-model="filter.no_invoice" name="filterInvoice"
                                id="filterInput" type="search" placeholder="No Invoice" @keyup="getVoid()"></b-form-input>

                            <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                                @click="onReset()">Reset</b-button>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-table bordered hover show-empty :busy="isBusy" :items="barang" :fields="fields" :perPage="perPage" responsive
                class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>
                <template v-slot:cell(no_invoice)="row">
                    <b-link :to="`input-data/pembelian/` + row.item.id_pembelian + `/detail`">
                        {{ row.item.no_invoice }}
                    </b-link>
                </template>
                <template v-slot:cell(status)="row">
                    <b-badge variant="primary" v-if="row.item.status == 0">Belum Proses</b-badge>
                    <b-badge variant="success" v-if="row.item.status == 1">Sudah Diproses</b-badge>
                    <b-badge variant="success" v-if="row.item.status == 2">Sudah Dibayar</b-badge>
                    <b-badge variant="success" v-if="row.item.status == 3">Diterima</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 4">Dibatalkan</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 5">Barang Selisih</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 6">Permintaan Void</b-badge>

                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">

                        <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Setuju"
                            @click="prepare(`${row.item.id}`, 'm-confirm-void')" name="setujuBtn">
                            <i class="fa fa-check "></i>
                        </b-button>

                        <b-button size="sm" class="mr-3 table-button-danger view " v-b-tooltip.hover title="Tolak"
                            @click="prepare(`${row.item.id}`, 'm-confirm-tolak')" name="tolakBtn">
                            <i class="fa fa-close "></i>
                        </b-button>


                    </div>
                </template>
            </b-table>

            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">{{ totalViewText }}</span>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>

        <!-- MODAL CREATE -->
        <b-modal id="m-create" class="modal-lg" title="Formulir Pembelian" no-close-on-backdrop no-close-on-esc hide-footer>

            <b-row>
                <b-col>
                    <b-form-group label="Supplier">
                        <multiselect v-model="form.id_supplier" :options="supplier" :internal-search="true"
                            :allow-empty="false" deselect-label="" select-label="" label="nama" track-by="id"
                            placeholder="-- Pilih Supplier --" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Ongkir" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                        <b-input-group class="pt-2">
                            <b-input-group-prepend>
                                <b-input-group-text>Rp.</b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input v-model="form.ongkir" id="ongkir" placeholder="" required=""></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group>
                <p>Keterangan</p>
                <b-form-textarea v-model="form.keterangan" id="basicTextarea" :rows="9"
                    placeholder="Content.."></b-form-textarea>
            </b-form-group>

            <div class="mt-4 text-right">
                <b-button variant="pgiBtn" @click="prepare('m-confirm-create')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-void" title="Confirm void" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk membatalkan pembelian ini ?" @submit="Void" />


        <ModalConfirm id="m-confirm-tolak" title="Confirm void" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk membatalkan pembelian ini ?" @submit="tolak" />


    </div>
</template>

<script>
import { thisExpression } from '@babel/types'

export default {
    name: 'approval-void-pembelian',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isSubmitting: false,
            isLoading: false,
            activeId: null,
            input: {
                show: 10
            },

            form: {
                tanggal: "",
                id_supplier: null,
                pic: "0999",
                ongkir: "",
                file: null,
                keterangan: ""
            },

            filter: {
                no_invoice: null,
                user_input: null,
            },
            fields: [
                { key: "no", label: "No", sortable: true },
                { key: "no_invoice", label: "No Invoice" },
                { key: "tanggal", label: "Tanggal" },
                { key: "supplier", label: "Supplier" },
                { key: "nama", label: "Nama Barang" },
                { key: "qty", label: "Qty" },
                { key: "satuan", label: "Satuan" },
                { key: "detail", label: "Detail" },
                { key: "harga", label: "Harga" },
                { key: "total", label: "Total Harga" },
                { key: "action", label: "Aksi" },
            ],

            options: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 30, text: '30' },
                { value: 40, text: '40' },
                { value: 50, text: '50' },
            ],
            barangAll: [],
            barang: [],
            supplier: [],
            supplierValue: [],

            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            query: "",
        }
    },
    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        init() {
            this.initStatus = 0
            this.page = 1
            this.query = "&page=" + this.page

            let getVoid = this.$axios.get("api/transaksi/pembelian/detail?status=4").then(response => {
                let datas = response.data.data
                let i = 1
                for (var data of datas.data) {
                    let harga = "Rp. " + data.harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                    let totalHarga = "Rp. " + data.total_harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                    this.barang.push({
                        no: i,
                        id: data.id,
                        id_pembelian: data.id_pembelian,
                        no_invoice: data.pembelian.no_invoice,
                        tanggal: data.pembelian.tanggal,
                        supplier: data.pembelian.supplier.nama,
                        nama: (data.tipe_barang == null) ? '-' : data.tipe_barang.tipe,
                        qty: data.jumlah,
                        satuan: data.satuan,
                        detail: data.detail_barang,
                        harga: harga,
                        total: totalHarga,
                    })
                    i++
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to

                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })

            Promise.all([getVoid]).then(() => {
                this.initStatus = 1
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },

        getVoid() {
            this.toggleBusy()
            this.$axios.get("api/transaksi/pembelian/detail?status=4" + this.query).then(response => {
                this.barang = []
                let datas = response.data.data
                let i = 1
                for (var data of datas.data) {
                    let harga = "Rp. " + data.harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                    let totalHarga = "Rp. " + data.total_harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                    this.barang.push({
                        no: i,
                        id: data.id,
                        id_pembelian: data.id_pembelian,
                        no_invoice: data.pembelian.no_invoice,
                        tanggal: data.pembelian.tanggal,
                        supplier: data.pembelian.supplier.nama,
                        nama: (data.tipe_barang == null) ? '-' : data.tipe_barang.tipe,
                        qty: data.jumlah,
                        satuan: data.satuan,
                        detail: data.detail_barang,
                        harga: harga,
                        total: totalHarga,
                    })
                    i++
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to

                this.toggleBusy()
                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.isLoading = false
                })
        },

        prepare(id, modal) {
            this.activeId = id
            this.$bvModal.show(modal)
        },

        Void() {
            let form = {
                id: this.activeId,
                status: 2
            }
            this.$axios.post("api/transaksi/pembelian/update/detail", form).then(() => {
                this.$bvModal.hide("m-confirm-void")
                this.$helper.toastSucc(this, "Pembelian Berhasil dibatalkan")

                this.getVoid()
            })
        },

        tolak() {
            let form = {
                id: this.activeId,
                status: 0
            }
            this.$axios.post("api/transaksi/pembelian/update/detail", form).then(() => {
                this.$bvModal.hide("m-confirm-tolak")
                this.$helper.toastSucc(this, "Void Berhasil ditolak")
                this.getVoid()
            })
        },



        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        onPageChange() {

            if (this.isLoading == false) {

                this.page = this.currentPage
                this.query = "&page=" + this.page

                this.getVoid()
            }

        },

        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },
    },

    created() {
        this.init()
    }
}
</script>
